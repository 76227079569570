.cc-form-container {

    .form-title, .cc-main-title {
        width: 100%;
        margin: 0 0 1rem;
        text-align: center;
        font-size: 16px;
    }
    
    .cc-sub-form-title {
        width: 100%;
        margin: 0 0 1rem;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
    }

    h2 fa-icon {
        margin-right: 0.5rem;
    }

    .cc-sub-form {
        
        .cc-sub-form-title:first-of-type {
            margin-bottom: 0;
        }
    }

    form.cc-form {
        display: flex;
        align-items: center;
        font-size: 14px;

        .row {
            width: 100%;
        }

        // Overwrite Syncfusion default border color
        .e-input-group, .e-input-group.e-control-wrapper, .e-checkbox-wrapper {
            border-color: rgb(var(--color-sf-on-surface-variant), 0.25) !important;
		}

        // Selects immediate children, regardless of tag type
        .cc-form-section {

            .section-title {
                font-size: 14px;
                font-weight: 600;
                margin: 0;
                text-align: center;
            }
        }

        .cc-form-group {
            width: 100%;

            .cc-list-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }

        .cc-input-container {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
    
            & > * {
    
                &:not(.icon) {
                    width: 100%;
                }
            }
    
            &.focus {
    
                .cc-input-icon {
                    color: rgba(var(--color-sf-primary));
                }
    
                .cc-label {
                    color: rgba(var(--color-sf-primary));
                }
    
                .cc-input-error {
                    opacity: 0.5;
                }
            }
    
            &.error {
                
                .cc-input-icon {
                    color: rgba(var(--color-sf-error));
                }
    
                .cc-input-error {
                    padding-right: calc(var(--bs-gutter-x) * .75);
                }
            }
    
            &.w100.col-md-6 {
                width: 100%;
            }
    
            .cc-label {
                top: -8px;
                font-size: 14px;
                font-weight: 600;
                transition: all 0.4s ease;
            }
    
            .cc-input {
                display: flex;
                position: relative;
                width: 100%;
    
                // Ensures syncfusion labels are positioned correctly with cc-input-icons
                label.e-label-bottom, input, textarea, .e-multi-select-wrapper .e-delim-view.e-delim-values, .e-multi-select-wrapper .e-chips-collection, .e-delim-values, .e-chips-collection, &.e-input-focus .e-searcher input, .e-searcher input  {
                    font-size: 14px !important;
                    padding-left: calc(var(--bs-gutter-x) * 1.75) !important;
                }
    
                .e-float-input, .e-float-input.e-control-wrapper {
                    margin-top: 0 !important;
                }
            }
    
            .e-checkbox-wrapper {
                width: 100%;
                padding-top: 4px;
                padding-bottom: 4px;
                padding-left: calc(var(--bs-gutter-x) * 1.75) !important;
    
                .e-label {
                    padding: 4px 0 4px;
                    color: rgb(var(--color-sf-on-surface-variant), 0.75);
                }
            }
    
            .cc-input-icon {
                position: absolute;
                top: 50%;
                left: calc(var(--bs-gutter-x) * .75);
                color: rgb(var(--color-sf-on-surface-variant), 0.75);
                font-size: 18px;
                transform: translateY(-50%);
                transition: all 0.25s ease;
            }
    
            .cc-input-error {
                position: absolute;
                right: 0;
                bottom: -20px;
                color: rgba(var(--color-sf-error));
                font-size: 11px;
                opacity: 1;
                transition: all 0.6s ease;
                animation: fadeInRight 0.6s ease;
            }
    
            .row > div {
                position: relative;
            }
        }

        .cc-sub-form {
            padding: 1rem;
            border: 1px solid rgba(var(--color-sf-primary));
            border-radius: 4px;
        }

        .cc-form-actions {
            display: flex;
            justify-content: center;
            flex-direction: row;
            gap: 2rem;

            button {
                min-width: 120px;
            }
        }
    }

    .row {
        --bs-gutter-y: 1rem;
    }
}


@keyframes fadeInRight {
    from {
        opacity: 0;
        margin-right: calc(var(--bs-gutter-x) * -.5);
    }
    to {
        opacity: 1;
        margin-right: 0;
    }
}