// cards
.e-card {

    &:hover {
        background: linear-gradient(0deg,
            rgba(var(--color-sf-primary), 0.05),
            rgba(var(--color-sf-primary), 0.05)),
            rgba(var(--color-sf-surface));
        border: unset;
    }
}