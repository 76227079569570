:root {
	--input-shadow: 0, 0, 0;
}

.e-dark-mode {
	--input-shadow: 255, 255, 255;
}

// Dialog that fills the page
.e-dlg-container {
    padding: 2rem;

    & > div, ejs-dialog {
        
        &:not(.e-dlg-overlay) {
            max-width: 1000px !important;
            max-height: 85vh !important;
        }
    }

    .row {
        --bs-gutter-y: 1rem;
        margin-top: 0; // Prevents overflow caused by the dialog header
    }
    
    .cc-form-container .row {
        --bs-gutter-y: 1.5rem;
    }

    .e-dlg-header {
        font-size: 24px;
        font-weight: 800;
    }

    .e-dlg-content {

        form {
            display: flex;
            flex-wrap: wrap;
            position: relative;

            h3 {
                font-size: 1.25em;
                margin: 0;
            }

            // Child forms
            form {
                position: relative;
            }

            input {
                margin-bottom: 0;
            }
        
            label {
                font-weight: 700;
                user-select: text;
                text-transform: capitalize;
            }
        
            .required {
        
                label {
        
                    &:after {
                        content: '*' !important;
                        margin-left: .5rem;
                        font-weight: 800;
                        font-size: 16px;
                        color: red !important;
                    }
                }
            }

            .form-group {
                position: relative;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
    
                label {
                    width: 33%;
                }
    
                & > *:not(label) {
                    padding-left: 1rem;
                    width: 77%;
                }

                // Error containers
                .error-container, form-required-field {
                    width: auto;
                    position: absolute;
                    bottom: -18px;
                    right: 10px;
                }
        
                .e-control {
                    position: relative;
        
                    .radio-buttons { 
                        display: flex;
                        align-items: center;
                        height: 100%;
        
                        ejs-radiobutton {
        
                            label .e-label {
                                color: rgba(var(--color-sf-outline));
                            }
                        }
                    }
                }
    
                .e-input-group, .e-control {
    
                    input {
                        padding-left: 6px;
                        background-color: transparent;
                    }
                }
        
                &.form-submit {
        
                    button {
                        max-width: 100%;
        
                        &:not(:last-child) {
                            margin-right: 1rem;
                        }
                    }
                }

                ejs-checkbox {
                    
                    label {
                        text-align: unset;
                    }
                }
            }
        
            input {
        
                &[type="radio"] {
                    margin: 0;
                }
            }
        }

        #case-file-form {

            .cc-form-section {
                margin-top: 1rem;
            }
        }
    }

    .e-footer-content {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2rem;

        .e-btn {
            
            .e-primary.e-flat:not([DISABLED]) {
                color: white;
            }
        }

        button {
            min-width: 120px;
            box-shadow: none;
            transition: opacity 0.4s ease;

            &:hover {
                opacity: 0.5;
            }
        }
    }
}

// Dialog that pops up relative to parent content
.e-dialog {

    &.e-popup-open {

        .e-footer-content {

            .e-btn {

                &.e-primary.e-flat:not([DISABLED]) {
                    color: white;
                }
            }
        }
    }
}