ejs-tab {

    .e-tab-header {

        .e-toolbar-items {
            width: 100%;

            .e-toolbar-item {
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                overflow: hidden;
                border-bottom: 3px solid rgba(var(--color-sf-primary), 0);
                transition: background-color .3s ease;

                &.e-active {
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;
                    border-bottom: 3px solid rgba(var(--color-sf-primary)) !important;

                    .e-tab-wrap {

                        .e-text-wrap {

                            .e-tab-text, .e-tab-icon {
                                color: rgb(var(--color-sf-primary));
                            }

                            &:before {
                                content: none;
                            }
                        }
                    }
                }

                &:not(.e-template) {

                    .e-tab-wrap {
                        display: flex;
                        justify-content: center;
                        margin-left: -6px;
                    }
                }

                &.e-template {

                    .e-tab-wrap {
                        display: flex;

                        .e-text-wrap {
                            height: unset;
                            width: 100%;

                            .e-tab-text, .e-tab-text .tab-header {
                                display: flex;
                                width: 100%;
                            }

                            .tab-header {
                                cursor: pointer;
                                justify-content: center;

                                & > * {
                                    cursor: pointer;
                                    padding: 0 4px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}