@import './components/all.scss';
@import './syncfusion/all.scss';

:root {
  --status-active: 75, 200, 75;
	--status-inactive: 200, 75, 75;
	--status-good: 145, 255, 160;
	--status-neutral: 150, 150, 150;
	--status-bad: 200, 75, 75;
	--status-no: 175, 175, 175;
	--status-yes: 100, 175, 200;
	--warning-yellow: #bfb02a;
	--input-shadow: 0, 0, 0;
  --comments-yellow: #ffef5b;
}

.e-dark-mode {
  --status-active: 75, 175, 175;
	--status-inactive: 200, 75, 75;
	--status-good: 60, 211, 75;
	--status-neutral: 150, 150, 150;
	--status-bad: 255, 50, 50;
	--status-no: 75, 75, 75;
	--status-yes: 15, 125, 100;
	--warning-yellow: #df9921;
	--input-shadow: 255, 255, 255;
  --comments-yellow: orange;
}


/**
 * Root
 */
::-webkit-scrollbar {
  background: none;
  scrollbar-width: thin;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(var(--color-sf-body-text), .1);
  border-color: rgba(var(--color-surface-body-text), 0.25);
}

body {
  margin: 0;
  overflow: hidden;
  color: rgb(var(--color-sf-body-text));
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background: linear-gradient(rgba(var(--color-sf-primary), 0.15), rgba(var(--color-sf-primary), 0.05));

  &.e-dark-mode {
    background: linear-gradient(rgba(var(--color-sf-surface-variant), 0.75), rgba(var(--color-sf-surface-variant), 0.75));
  }
}

/**
 * Typography
 */
h1, .h1 {
  font-weight: 800;
}

h2, .h2 {
  font-size: 1.5rem;
  font-weight: 800;
}

h3, .h3 {
  font-size: 1.34rem;
  font-weight: 800;
}

h4, .h4 {
  font-size: 1.1rem;
  font-weight: 800;
}

a {
  cursor: pointer;
}

button {
  cursor: pointer;
  color: rgba(var(--color-sf-on-surface-variant));
  display: flex;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  margin: 0;
  padding: 0 7px;
  border-radius: 4px;
  border: none;
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  text-transform: none;

  &.e-btn {
    color: rgb(var(--color-sf-body-text));
  }
  
  &.e-primary {
    color: white !important;
    border: none !important;
    background: rgb(var(--color-sf-primary)) !important;
  }

  &.e-link.e-flat {

    &:hover {
      background-color: transparent;
    }
  }

  &.e-outline {
    border: 1px solid currentColor !important;
  }

  &.e-tertiary {
    background: rgb(var(--color-sf-tertiary)) !important;
  }

  &.e-tertiary-outline {
    color: rgb(var(--color-sf-tertiary)) !important;
    border: 1px solid currentColor !important;
  }
}

.w100, .w-100 {
  width: 100%;
}

.h100 {
  height: 100%;
}

.hide {
  display: none;
}

/*
 * Backgrounds
 */
.bg {

  &-primary {
    background: rgb(var(--color-sf-primary));
  }

  &-secondary {
    background: rgb(var(--color-sf-secondary));
  }

  &-tertiary {
    color: white;
    background: rgb(var(--color-sf-tertiary));
  }

  &-btn-color {
    background: rgb(var(--color-sf-button-color));
  }
}

/**
 * Toast Messenger
 */
.e-toast-container {
  .e-toast {
    padding: 8px 16px;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 0.9;
    }

    .e-toast-message {
      .e-toast-title {
        h1 {
          display: flex;
          align-items: center;
          gap: 8px;
          margin: 0;
          font-size: 1rem;
          font-weight: 600;

          i {
            font-size: 1.2em;
          }
        }
      }

      .e-toast-content {
        margin-top: 4px;
        font-size: 0.9rem;
      }
    }

    i {
      &.e-toast-danger {
        color: rgb(var(--color-sf-error));
      }
      &.e-toast-warning {
        color: rgb(var(--color-sf-warning));
      }
      &.e-toast-success {
        color: rgb(var(--color-sf-success));
      }
    }
  }
}

/*
* Animation classes
*/
.loading {
  display: flex;

  &-text {
    animation: fadeIn .75s alternate infinite;
  }
}

.typing-indicator {
  display: flex;
  align-items: center;
  justify-content: space-around;

  .dot {
    width: 6px;
    height: 6px;
    margin: 0 2px;
    border-radius: 50%;
    background-color: rgba(182, 181, 186, 0.75);
    animation: typing 1.5s infinite;

    &:nth-child(1) {
      animation-delay: 0s;
    }

    &:nth-child(2) {
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
    }
  }
}

.user-editing {
  display: flex;
  justify-content: center;
  align-items: center;

  .e-icons {
    width: 20px;
    height: 20px;

    &.e-user {
      display: flex;
      align-items: center;
      margin-right: 4px;

      &:before {
        padding: 4px;
        border-radius: 50%;
        background-color: rgba(182, 181, 186, 0.75);
      }
    }
  }

  .username {
    font-size: 12px;
    padding: 0 4px;
  }
}

.warning {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  margin-top: 8px;
  border-radius: 6px;
  background-color: rgba(var(--color-sf-primary-container), 0.5);

  .warning-text { 
    color: rgba(var(--color-sf-warning));
    font-style: italic;
  }
}

.capitalize {
  text-transform: capitalize;
}

/**
 * Animations
 */
@keyframes fadeIn {

  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeInLight {

  from {
    opacity: .25;
  }

  to {
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    /* Initial state */
  }

  50% {
    transform: scale(1.5);
    /* Scale up to 1.5 times */
  }

  100% {
    transform: scale(1);
    /* Scale back to initial state */
  }
}

@keyframes typing {

  0%,
  80%,
  100% {
    transform: scale(0);
    /* Dots are hidden */
  }

  40% {
    transform: scale(1);
    /* Dots are visible */
  }
}