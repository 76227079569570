/* You can add global styles to this file, and also import other style files */

// Theme styles
@import 'styles/material3.scss';
@import 'styles/case-compass.scss';

// Layout
@import 'bootstrap/dist/css/bootstrap-grid.min.css';

// Components
@import '../node_modules/@aws-amplify/ui-angular/theme.css';
@import '../node_modules/@syncfusion/ej2-richtexteditor/styles/bootstrap5.css';