@use '@angular/material' as mat;

/* You can add global styles to this file, and also import other style files */

// Theme styles
@import 'styles/material3.scss';
@import 'styles/case-compass.scss';

// Layout
@import 'bootstrap/dist/css/bootstrap-grid.min.css';

// Components
@import '../node_modules/@aws-amplify/ui-angular/theme.css';
@import '../node_modules/@syncfusion/ej2-richtexteditor/styles/bootstrap5.css';
@import "../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";

// Global styles for Material Dialog
.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
  background: rgba(0, 0, 0, 0.32);

  &.cdk-overlay-backdrop-showing {
    opacity: 1;
  }
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;

  // Add these styles for Material Dialog
  .mat-mdc-dialog-container {
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    
    .mdc-dialog__surface {
      display: flex;
      flex-direction: column;
      height: 100%;
      max-height: 100%;
    }

    .mdc-dialog__content {
      flex: 1;
      overflow: hidden;
    }
  }
}
