.e-toast {

    .e-toast-neutral {
        background-color: rgba(var(--color-sf-outline));
    }

    .e-toast-message {

        .e-toast-title {
            display: flex;
            align-items: center;
            font-size: 1.25rem !important;
            font-weight: 600 !important;

            i {
                font-size: 1rem;
            }
        }

        .e-toast-content {

            h1, h2, h3, h4, h5, h6, p, ul, li {
                margin-top: 0;
                margin-bottom: 1rem;
            }

            a {
                color: #b0d6ff;
                text-decoration: underline;
            }
        }
    }
}